const data = [
    {
        id:1,
        title:"AWS",
        image:"img/project/aws.jpg",
        category: "DevOps",
        details:"DevOps Tool",
    },
    {
        id:2,
        title:"Database",
        image:"img/project/database_updated.jpg",
        category: "Web Development",
        details:"Web Tool",
    },
    {
        id:3,
        title:"Django",
        image:"img/project/django_updated.jpg",
        category: "Web Development",
        details:"Django Web framework",
    },
    {
        id:4,
        title:"Docker",
        image:"img/project/docker.jpg",
        category: "DevOps",
        details:"DevOps Tool",
    },
    {
        id:5,
        title:"DotNet",
        image:"img/project/dotnet.jpg",
        category: "Software Development",
        details:"Software Development Tool",
    },
    {
        id:6,
        title:"Flutter",
        image:"img/project/flutter_updated.jpg",
        category: "Mobile App Development",
        details:"Mobile App Development Tool",
    },
    {
        id:7,
        title:"Java",
        image:"img/project/java_updated.jpg",
        category: "Software Development",
        details:"Software Development Tool",
    },
    {
        id:8,
        title:"Python",
        image:"img/project/python_updated.jpg",
        category: "Software Development",
        details:"Software Development Tool",
    },
    {
        id:9,
        title:"React",
        image:"img/project/react-logo.jpg",
        category: "Web Development",
        details:"Web Development Tool",
    },
    {
        id:10,
        title:"React Native",
        image:"img/project/reactnative.jpg",
        category: "Mobile App Development",
        details:"Mobile App Development Tool",
    },
    // {
    //     id:11,
    //     title:"App",
    //     image:"img/project/project-3.jpg",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
    // {
    //     id:12,
    //     title:"App",
    //     image:"img/project/project-1.jpg",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
];

export default data;