import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const scrollTop=()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
      <footer className={props.footerAddclass} >
        <div className="container">
          <div className="footer-top">
            <div className="row">
              
              <div className="col-md-6 col-lg-6 col-xl-6">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link scrollTo={scrollTop} to={"/"}>
                      <img height={50} src={process.env.PUBLIC_URL +"/img/text_logo_white_png.png"} alt="FooterImg" />
                    </Link>
                  </div>
                  <p>
                  Ready to collaborate with our exceptional team of IT professionals? Contact us today to discuss your IT needs and discover how our talented team can empower your business to reach new heights of success
                  </p>
                  <ul className="social-media-icons">
                    
                    <li>
                      <a href="https://www.facebook.com/piagencyy?mibextid=ZbWKwL">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    
                    <li>
                      <a href="https://www.twitter.com/">
                        <i class="fa-brands fa-x-twitter"></i>
                      </a>
                    </li>
                    
                    <li>
                      <a href="https://www.linkedin.com/company/piagency2022/">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
             
             {/* <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Our Services</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service-details`} onClick={scrollTop}>Strategy &amp; Research</Link>
                      <Link>Strategy &amp; Research</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service-details`} onClick={scrollTop}>Web Development</Link>
                      <Link>Web Development</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service-details`} onClick={scrollTop}>Web Solution</Link>
                      <Link>Web Solution</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service-details`} onClick={scrollTop}>Digital Merketing</Link>
                      <Link>Digital Merketing</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service-details`} onClick={scrollTop}>App Design</Link>
                      <Link>App Design</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service-details`} onClick={scrollTop}>App Development</Link>
                      <Link>App Development</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              
              {/* <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>About Us</Link>
                      <Link onClick={() => scrollToDiv('home_about')}>About Us</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service`} onClick={scrollTop}>Services</Link>
                      <Link onClick={() => scrollToDiv('home_services')}>Services</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>Project</Link>
                      <Link onClick={() => scrollToDiv('project')}>Tools</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Blog</Link>
                      <Link onClick={() => scrollToDiv('features')}>Approach</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>Career</Link>
                      <Link onClick={() => scrollToDiv('home_testimonial')}>Testimonials</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service`} onClick={scrollTop}>Pricing Plan</Link>
                      <Link onClick={() => scrollToDiv('price')}>Pricing Plan</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              
              <div className="col-md-6 col-lg-6 col-xl-6">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="tel:+8801717804346">+880 17178-04346</a>
                      <a href="tel:+8801876516367">+880 18765-16367</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="mailto:contact@piagency.tech">contact@piagency.tech</a>
                      <a href="mailto:support@piagency.tech">support@piagency.tech</a>
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>Baridhara DOHS, Dhaka, Bangladesh</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span style={{color: "#75DAB4"}}>
                    &copy; Copyright 2023. Designed and Developed by <b>PiAgency</b>
                  </span>
                </div>
              </div>
              
              {/* <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link to={"#"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Terms of Use</Link>
                  </li>
                </ul>
              </div> */}

            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
