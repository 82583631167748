import React, { useState } from 'react';
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen]=useState(false)
  return (
    <>
      <section className="about-area sec-mar-bottom" id="home_about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Get To Know</span>
                <h2>About Us</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>We do design, code &amp; develop Software finally launch.</h3>
                {/* <h3>We are a united team of tech enthusiasts, empowering businesses with innovative IT solutions across sectors.</h3> */}
                <p>At Pi Agency, we are a team of passionate IT professionals dedicated to helping businesses thrive in the digital world. With a strong focus on innovation and client-centric solutions, we empower organizations to leverage technology effectively and achieve their goals</p>
                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <div className="company-logo">
                    {/* <img src={`${process.env.PUBLIC_URL} ${props.image}`} alt="about-images" /> */}
                    <img src={process.env.PUBLIC_URL + "/img/text_logo_black_png.png"} alt="about-images" />
                  </div>
                  <strong>#1</strong>
                  <h4>Best Creative IT Agency And Solutions <p>At Your Service <span style={{color: "#75DAB4", display: "inline"}}>24/7</span></p></h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img src={process.env.PUBLIC_URL + '/img/group/picture-2.jpg'} alt="about-images" />
                </div>
                {/* <div className="banner-2">
                  <img src={process.env.PUBLIC_URL + '/img/about-baner-2.jpg'}  alt="about-images" />
                  <div className="banner2-inner">
                    <div className="play">
                      <div className="video-popup" onClick={()=>setOpen(true)} ><i className="fas fa-play" /></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>  
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay = {1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment> 
    </>
  )
}

export default AboutArea