import React from "react";
import "swiper/css/autoplay";
import SingleTeamCard from "./SingleTeamCard";
// import Swiper core and required modules
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function TeamWrap(props) {
    const teamSlider = {
      autoplay:{
        delay: 2500,
        disableOnInteraction: false,
      },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }
    }
  return (
    <>
      <section className="our-team sec-mar">
        <div className="container">
          <div
            className="title-wrap  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Our Team</span>
              <h2>Meet Our Team</h2>
              <p>
              At Pi Agency, our success lies in the dedication and expertise of our talented team. We have assembled a group of passionate professionals who are committed to delivering exceptional IT solutions and outstanding customer service.
              </p>
            </div>
          </div>
          <Swiper {...teamSlider} className="swiper team-slider">
            <div className="swiper-wrapper">
              
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/group/ceo-small.jpg"}
                  name="Farhan Adil"
                  title="Founder, CEO"
                />
              </SwiperSlide>
              
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/group/shakur-small.jpg"}
                  name="Shakur Shams Mullick"
                  title="Senior Software Engineer"
                />
              </SwiperSlide>
              
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/group/sajal-small.jpg"}
                  name="Arif Sajal"
                  title="Software developer"
                />
              </SwiperSlide>
              
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/group/mahin-small.jpg"}
                  name="Mahian Mahin"
                  title="Full stack Web developer"
                />
              </SwiperSlide>
              
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/group/shopno-small.jpg"}
                  name="Gopal Debnath"
                  title="Head of HR and manager"
                />
              </SwiperSlide>

            </div>
            <div className="swiper-pagination" />
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default TeamWrap;
