import React from 'react';
function FeaturesArea() {
  
  return (
    <>
     <section className="features-area" id="features">
        <div className="container">
          <div className="title-wrap  wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
            <div className="sec-title white">
              <span>Care Study</span>
              <h2>Our Approach</h2>
              <p>We follow a streamlined and efficient working process to ensure smooth project execution and exceptional results. Our well-defined process allows us to deliver projects on time, within budget, and with utmost client satisfaction.
</p>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + "/img/icons/service-icon-5.png"} alt="Feature-img" />
                  </div>
                  {/* <CountUp end={150} delay={3} duration={5}  /> <sup>+</sup> */}
                  <h4>System Design</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + "/img/icons/service-icon-2.png"} alt="Feature-img" />
                  </div>
                  {/* <CountUp end={2150} delay={3} duration={5} /> <sup>+</sup> */}
                  <h4>Developing</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"} alt="Feature-img" />
                  </div>
                  {/* <CountUp end={120} delay={3} duration={5} /> <sup>+</sup> */}
                  <h4>Deployments</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/service-icon-1.png'} alt="Feature-img" />
                  </div>
                  {/* <CountUp end={50} delay={3} duration={5} /> <sup>+</sup> */}
                  <h4>Maintenance</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default FeaturesArea