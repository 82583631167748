import React from 'react';
import { Link } from 'react-router-dom';
import scrollToDiv from '../../../Scroll';
// import { Link } from 'react-scroll';

function HeroArea() {

    const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
     <section className="hero-area" id="hero">
        <div className="ken-burns-slideshow">
          <img src={process.env.PUBLIC_URL + "/img/group/picture-1.jpg"} alt="HeroBanner" />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li><a target='blank' href="https://www.facebook.com/piagencyy?mibextid=ZbWKwL">Facebook</a></li>
            <li><a target='blank' href="#">Twitter</a></li>
            <li><a target='blank' href="https://www.linkedin.com/company/piagency2022/">Linkedin</a></li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  {/* <h1>Creative &amp; Minimal<span>It Agency.</span></h1> */}
                  <h1>Welcome to<span>Pi Agency.</span></h1>
                  <p>At Pi Agency, we believe that technology is the driving force behind modern businesses. As a leading IT solutions provider, we are committed to helping businesses of all sizes harness the power of technology to drive growth, enhance productivity, and achieve their goals.</p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      {/* <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>About Us</Link> */}
                      <Link onClick={() => scrollToDiv("home_about")}>About Us</Link>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      {/* <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>Get in Touch</Link> */}
                      <Link onClick={() => scrollToDiv("home_testimonial")}>Our Reviews</Link>
                      
                      
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HeroArea