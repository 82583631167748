import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
  return (
    <>
      <div className="project-area sec-mar" id="project">
        <div className="container">
          <div className="title-wrap">
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Weapons</span>
              <h2>Our Tools</h2>
              <p>
              We offer a comprehensive suite of cutting-edge tools designed to streamline operations, boost efficiency, and maximise productivity.
              </p>
            </div>
          </div>
          <ProjectCardFilter/>
        </div>
      </div>
    </>
  );
}

export default ProjectWrap;
