import React from 'react';
import { Link } from 'react-router-dom';
import "swiper/css/autoplay";
// import Swiper core and required modules
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay, EffectFade,Pagination,]);
function Testimonial() {
    const testimonialSlider = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        duration: 5000,
        speed: 1000,
        autoplay:{
          delay: 3500,
          isableOnInteraction: true,
        },
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    }
  return (
    <>
      <section className="testimonial-area" id="home_testimonial">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonial</span>
              <h2>What Our Clients Say About Us</h2>
              <p>Discover why our clients rave about our exceptional services and the transformative impact we deliver</p>
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              
              
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Raiyan Kamal</h5>
                  <span>CTO of Akindi</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Working with Pi Agency has been a game-changer for our business. Their expertise in software development helped us bring our innovative ideas to life. Not only did they deliver a high-quality solution on time and within budget, but their team also provided valuable guidance throughout the entire process. Thanks to Pi Agency, we've gained a competitive edge and achieved remarkable growth. I highly recommend their services!</p>
                  {/* <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" />
                  </div> */}
                </div>
              </SwiperSlide>
              
              
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Md. Aminur Rahman</h5>
                  <span> MD of Bengaltroops</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>We engaged Pi Agency for our web development project, and we couldn't be happier with the results. Their team of skilled developers listened to our requirements and transformed our vision into a stunning website. They delivered a user-friendly interface, seamless navigation, and incorporated the latest design trends. Their attention to detail and commitment to quality truly set them apart. Our website has received rave reviews from clients and has become a powerful tool for our business. We highly recommend Pi Agency for web development projects.</p>
                  {/* <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="testimonial-img" />
                  </div> */}
                </div>
              </SwiperSlide>
              
              
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Almaasta</h5>
                  <span>CEO of Bashotech</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Implementing DevOps practices with the help of Pi Agency has revolutionized our software development process. Their expertise in DevOps methodologies and tools has streamlined our operations, enabling faster and more frequent releases with improved quality. They seamlessly integrated our development, testing, and deployment processes, resulting in enhanced collaboration and efficiency. The continuous integration and automated deployment setup provided by Pi Agency have reduced errors and downtime significantly. We are extremely satisfied with the DevOps services and the positive impact they have had on our organization</p>
                  {/* <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" />
                  </div> */}
                </div>
              </SwiperSlide>
              
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>  
    </>
  )
}

export default Testimonial